import Banner from '../..//Banner';
import ComicsList from '../../ComicsList';

const ComicsPage = () => {

    return (
        <>
        <Banner/>
        <ComicsList/>
      </>
    )
}

export default ComicsPage;